import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { rhythm } from "../../utils/typography"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Services = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Video content creation for your online course - Services" />
      <article style={{ margin: `auto`, maxWidth: rhythm(34) }}>
        <header>
          <h1>Let's get visual</h1>
          <h2 style={{ marginTop: `0`, textAlign: `center` }}>
            Video content creation for your online course
          </h2>
        </header>

        <Image
          fluid={data.mast.childImageSharp.fluid}
          alt="Online course creation"
          style={{
            width: `560px`,
            maxWidth: `100%`,
          }}
        />

        <p>
          Whether you have a great idea for an online course or are in the
          middle of writing one, I would love to help you with the visual
          elements of it. To be precise: take care of the video production part
          of your course. It can be as simple as recording a welcome message, or
          if you want to go all out your whole course could be delivered through
          video.
        </p>

        <p>
          Creating written content is very different to creating video content
          and I often find that clients struggle to translate one to the other.
          This is where I can help.
        </p>

        <p>
          There are so many different options of getting your content across,
          whether it is a piece to camera (either by yourself or other
          contributors), practical ‘how to' videos that take your participants
          through making/cooking/baking something step-by-step, motion graphics
          with a voiceover to highlight a particular topic of your course or
          maybe even an interview or case study to give real life examples.
        </p>

        <p>
          The possibilities are pretty much endless, which is great and the
          reason why I love creating video content. But I also know that it can
          feel overwhelming and sometimes hard to visualise what the end product
          might look like. I will work with you to find the best approach for
          delivering your online course and together we will create some awesome
          content.
        </p>

        <Image
          fluid={data.creator.childImageSharp.fluid}
          alt="Video content creator"
          style={{
            width: `560px`,
            maxWidth: `100%`,
          }}
        />

        <p>
          You might wonder why you should invest in professionally produced
          video content for your course. Let me ask you this - how great do you
          think is the impact of the non-verbal part of a presentation compared
          to what is actually being said? It's a whopping 93%.
        </p>

        <p>
          Online courses have become hugely popular, particularly this year, and
          content is obviously key but if you would like to stand out amongst
          the sea of other course creators, I think it's invaluable for your
          videos to look and sound professional. This applies to both big and
          small brands - so whether you're a startup, one-woman-band or have
          established yourself in the market already, I'd love to help you take
          your courses to the next level!
        </p>

        <Image
          fluid={data.editor.childImageSharp.fluid}
          alt="Video content editor"
          style={{
            width: `560px`,
            maxWidth: `100%`,
          }}
        />

        <p>
          It could be as simple as a welcome message or an introduction to the
          course. This is a really nice way of giving people a taster of what's
          to come and for them to meet their teacher. People love learning from
          people, so even if the rest of your course isn't video based, a nicely
          produced intro video would be a great option to build that personal
          connection
        </p>

        <p>
          Another possibility is to cut a promo out of the content captured for
          this intro video, as again it will work really well to grab people's
          attention, get your personality across and promote your course at the
          same time.
        </p>

        <p>
          Course creators who have instructional parts in their course ie. a
          step by step guide on how to make a certain thing, could include a few
          ‘how to' videos to break up the other content. Photography can be
          great for this too but video just adds that extra dimension - and the
          process and progress of something being made is super interesting to
          watch.
        </p>

        <p>
          For anyone who wants to go all out and have their course predominantly
          delivered through video, think Masterclass (I love these, they are so
          beautifully done) I'd recommend choosing a supplier who can help you
          “translate” your content into video, advise you on structure and above
          all deliver great quality and beautifully shot video content. Not
          talking about anyone in particular here But in all seriousness, I'd
          love to help you take your courses and therefore your brand to the
          next level!
        </p>

        <h2 style={{ textAlign: `center` }}>
          I have different packages, depending on your budget...
        </h2>

        <h3>Firstly, I would need to know:</h3>
        <ul>
          <li>
            Are the video elements of the course predominately Pieces to Camera
            (PTCs)?
          </li>
          <li>
            Will the content require lots of cutaways and sequences and is there
            a ‘how to element' to it?
          </li>
          <li>Will there be any graphic elements needed in the edit?</li>
          <li>
            Does any existing footage/images have to be included in the edits?
          </li>
        </ul>

        <h3>Also, costs can increase depending on the following...</h3>
        <ul>
          <li>number of pre-production days</li>
          <li>shoot and edit days</li>
          <li>number of crew</li>
          <li>specs of cameras and lenses</li>
          <li>location hire</li>
          <li>auto-cue</li>
          <li>more elaborate graphic elements or motion graphics</li>
          <li>length and number of videos</li>
          <li>number of music licenses</li>
        </ul>

        <div className="tight">
          <h2>WANT TO DISCUSS YOUR ONLINE COURSE IDEA?</h2>
          <h3>SEND ME AN EMAIL AND WE'LL ARRANGE A CHAT</h3>
        </div>
      </article>
    </Layout>
  )
}

export default Services

export const pageQuery = graphql`
  query {
    mast: file(absolutePath: { regex: "/online-course-creation.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 560) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    creator: file(absolutePath: { regex: "/video-content-creator.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 560) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    editor: file(absolutePath: { regex: "/video-content-editor.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 560, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
